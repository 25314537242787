<template>
  <el-card>
    <div slot="header" class="card-header">
      <h3 style="margin: 10">DAILY TIME IN/OUT DASHBOARD</h3>
    </div>
    <el-dialog title="CHOOSE TODAY WORK SETTING" :visible.sync="dialog" :width="diaglogformWidth">
      <el-form ref="form" :model="form" :rules="rules" label-width="180px" :label-position="labelPosition">
        <el-form-item label="Work Type" prop="work_type_bg4_id">
          <el-select style="width:100%" v-model="form.work_type_bg4_id">
            <el-option v-for="type in types" :key="type.id" :label="type.name" :value="type.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Work Schedule" prop="tas_id">
          <el-select style="width:100%" v-model="form.tas_id" placeholder="Select Shift">
            <el-option v-for="shift in shifts" :key="shift.id" :value="shift.id" :label="shift.name"></el-option>
          </el-select>
        </el-form-item>
        <p style="margin:0">Are you working on your Rest Day? If yes, check Working at Rest Day.</p>
        <el-form-item label="Working at Rest Day">
          <!-- <el-checkbox v-model="form.is_overtime_timeout"></el-checkbox> -->
          <el-checkbox :value="1" v-model="form.git"></el-checkbox>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">Cancel</el-button>
        <el-button type="primary" @click="timein">Confirm</el-button>
      </span>
    </el-dialog>
    <el-row type="flex" justify="center">
      <div style="text-align: center">
        <p style="margin: 0">{{day}}</p>
        <p style="margin: 0">{{date}}</p>
        <p style="margin: 0 0 1rem 0"><b>{{time}}</b></p>
        <el-button type="primary" @click="showWorkTypes">IN</el-button>
        <el-button type="primary" @click="timeout">OUT</el-button>
      </div>
    </el-row>
    <el-row type="flex" justify="center">
      <p>Timeout that will fall at 12:01 am onwards, or not on the same day, click 'Out Midnight'.</p>
    </el-row>
    <el-row type="flex" justify="center">
      <el-button type="primary" icon="el-icon-timer" @click="timeOutMidnight()">OUT MIDNIGHT</el-button>
    </el-row>
    <el-divider></el-divider>
    <el-table :data="data" border class="hidden-xs-only">
      <el-table-column label="DEPARTMENT" prop="bd_id_name"></el-table-column>
      <el-table-column label="EMPLOYEE" prop="employee_name"></el-table-column>
      <el-table-column label="TIME" header-align="center">
        <el-table-column label="IN" prop="logtime_in"></el-table-column>
        <el-table-column label="TYPE" prop="logtime_in_type_name"></el-table-column>
        <el-table-column label="OUT" prop="logtime_out"></el-table-column>
        <el-table-column label="TYPE" prop="logtime_out_type_name"></el-table-column>
      </el-table-column>
      <el-table-column label="WORK TYPE" prop="work_type_bg4_id_name"></el-table-column>
    </el-table>
    <el-table :data="data" border class="hidden-sm-and-up">
      <el-table-column label="EMPLOYEE" prop="employee_name"></el-table-column>
      <el-table-column label="IN" prop="logtime_in"></el-table-column>
      <el-table-column label="OUT" prop="logtime_out"></el-table-column>
    </el-table>
  </el-card>
</template>

<script>
import { format } from 'date-fns'
export default {
  data () {
    return {
      date: this.getDate(),
      day: this.getDay(),
      dialog: false,
      labelPosition: 'top',
      diaglogformWidth: '30%',
      windowWidth: window.innerWidth,
      types: [],
      shifts: [],
      data: [],
      form: {
        tar_id: 0,
        bbl_id: this.$cookies.get('homebase'),
        computer_name: 'WEBHRIS',
        tas_id: null,
        is_overtime_timeout: 0,
        is_nonschedule: 0,
        logtype: null,
        timekeep_signon: 1215,
        work_type_bg4_id: null,
        logtime_in_type: 0,
        logtime_out_type: 0,
        em_id: this.$store.getters.user.employeeId
      },
      rules: {
        work_type_bg4_id: [
          { required: true, message: 'Work Type is required' }
        ],
        tas_id: [
          { required: true, message: 'Work Schedule is required' }
        ]
      },
      interval: null,
      time: null
    }
  },
  watch: {
    windowWidth () {
      this.checkWindowWidth()
    }
  },
  mounted () {
    this.form.logtime_in_type = window.screen.width > 768 ? 1205 : 1204
    this.form.logtime_out_type = window.screen.width > 768 ? 1205 : 1204
    this.checkWindowWidth()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize, true)
    })
    this.getWorkTypes()
    this.getTimelog()
    this.getShiftForDay()
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  created () {
    this.interval = setInterval(() => {
      this.time = Intl.DateTimeFormat(navigator.language, {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }).format()
    }, 1000)
  },
  methods: {
    getTimelog () {
      const form = {
        logdate: format(new Date(), 'MM/dd/yyyy')
      }
      this.$http
        .post('timekeep.app/dashboard-timelog-list', form)
        .then(res => {
          this.data = res.data.StatusCode ? res.data.body : []
        })
    },
    getWorkTypes () {
      this.$http
        .get('backend.global4-list/90')
        .then(res => {
          this.types = res.data.body
        })
    },
    getDate () {
      return format(new Date(), 'dd MMMM yyyy')
    },
    getDay () {
      return format(new Date(), 'iiii')
    },
    getShiftForDay () {
      const form = {
        bbl_id: this.$cookies.get('homebase'),
        date: format(new Date(), 'MM/dd/yyyy')
      }
      this.$http
        .post('timeekeep.app/location-shift-list', form)
        .then(res => {
          this.shifts = res.data.body
          this.form.tas_id = null
        })
    },
    showWorkTypes () {
      this.dialog = true
    },
    timein () {
      const name = 'form'
      this.$refs[name].validate(valid => {
        if (valid) {
          this.dialog = false
          this.form.logtype = 'A'
          this.$http
            .post('timekeep.app/location-timelog', this.form)
            .then(res => {
              this.$message({
                message: 'Time In successful',
                type: 'success'
              })
              this.getTimelog()
            })
        }
      })
    },
    timeout () {
      this.form.logtype = 'Z'
      this.form.tas_id = 0
      this.$http
        .post('timekeep.app/location-timelog', this.form)
        .then(res => {
          this.$message({
            message: 'Time Out successful',
            type: 'success'
          })
          this.getTimelog()
        })
    },
    logTimeOut (isOvertime) {
      this.form.logtype = 'Z'
      this.form.tas_id = 0
      this.form.is_overtime_timeout = isOvertime
      this.$http
        .post('timekeep.app/location-timelog', this.form)
        .then(res => {
          this.$message.success('Timeout midnight successful')
        })
        .catch(() => {
          this.$message({
            showClose: true,
            type: 'danger',
            message: 'Uh-oh! Something went wrong.'
          })
        })
    },
    timeOutMidnight () {
      this.$http
        .get(`timekeep.app/location-timelog-timein/${this.$store.getters.user.employeeId}`)
        .then(res => {
          if (res.data.StatusCode) {
            this.$confirm(`You are going to overtime, please confirm this details<br><br>From: ${res.data.body.logfrom}<br>To: ${res.data.body.logto}<br><br>Total Hours: ${res.data.body.logtotal_hours}`, 'Time Out Midnight', {
              dangerouslyUseHTMLString: true,
              distinguishCancelAndClose: true,
              confirmButtonText: 'Confirm and Timeout',
              cancelButtonText: 'Cancel'
            })
              .then(() => {
                this.logTimeOut(1)
              })
              .catch(() => {
                this.$message({
                  type: 'info',
                  message: 'Timeout Midnight Cancelled'
                })
              })
          } else {
            this.$message({
              type: 'error',
              message: res.data.body
            })
          }
        })
    },
    onResize () {
      this.windowWidth = window.innerWidth
    },
    checkWindowWidth () {
      if (this.windowWidth < 600) {
        this.labelPosition = 'top'
        this.diaglogformWidth = '80%'
      } else {
        this.labelPosition = 'left'
        this.diaglogformWidth = '30%'
      }
    },
    beforeDestroy () {
      window.removeEventListener('resize', this.onResize)
    }
  }
}
</script>

<style lang="scss" scoped>
  p {
    font-size: 1.5rem;
  }
</style>
